import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormProvider from '../../../../../shared/FormProvider'
import { FormLabel, Grid, MenuItem } from '@mui/material'
import RHFTextField from '../../../../../shared/RHFTextfield'
import { inputStyles, labelStyles } from '../../../../../certification/EditCertificate'
import { RHFDatePicker } from '../../../../../shared/RHFDatePicker'
import { componentMapping } from '../../../../../../helper/CommonAPI'
import RHFSelect from '../../../../../shared/RHFSelect'

const schema = yup.object().shape({
  volumne: yup.number('Please provide volumn in numbers'),
  component: yup.string(),
  expiry_date: yup.date(),
  collection_date: yup.date()
})

export const EditComponentForm = ({ item, closeDialog, confirmDialog }) => {
  const defaultValues = {
    volume: item?.volume,
    expiry_date: item?.expiry_date,
    collection_date: item?.collection_date,
    component: item?.component
  }
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors }
  } = methods

  const onSubmit = async data => {
    await confirmDialog(item?.id, data)
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <FormLabel sx={labelStyles}>Edit volume(ml)</FormLabel>
          <RHFTextField sx={inputStyles} fullWidth size='small' placeholder='Edit Volumne' name='volume' />
        </Grid>
        {/* <Grid item xs={12} sm={10} md={8} lg={6}>
          <FormLabel sx={labelStyles}>Edit Expiry Date</FormLabel>
          <RHFDatePicker sx={inputStyles} fullWidth size='small' placeholder='Edit Date' name='expiry_date' />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <FormLabel sx={labelStyles}>Edit Collection Date</FormLabel>
          <RHFDatePicker sx={inputStyles} fullWidth size='small' placeholder='Edit Date' name='collection_date' />
        </Grid> */}
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <FormLabel sx={labelStyles}>Edit Component</FormLabel>
          <RHFSelect sx={inputStyles} fullWidth size='small' placeholder='Edit Date' name='component'>
            {componentMapping.map((item, idx) => (
              <MenuItem value={item.component}>{item.component}</MenuItem>
            ))}
          </RHFSelect>
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          marginTop: '1rem',
          justifyContent: 'flex-end'
        }}
      >
        <button className='btn backBtn' onClick={closeDialog}>
          Close
        </button>
        <button type='submit' className='btn backBtn btn-gradient-custom'>
          Save
        </button>
      </div>
    </FormProvider>
  )
}
