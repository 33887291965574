// ** React Import
import { useEffect, useRef, useState } from 'react'

// ** MUI Imports
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

// ** Third Party Components
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Component Imports
import { Link, useLocation } from 'react-router-dom'
import Drawer from './Drawer'
import VerticalNavHeader from './VerticalNavHeader'
import {
  checkPermission,
  getDonorNameSans,
  getEntityInfo,
  getInitials,
  getTenatNameSans
} from '../../app/middleware/auth'
import {
  SideMenuCampCustomCollapse,
  SideMenuCustom,
  SideMenuCustomCollapse,
  SideMenuDonorCollapse,
  SideMenuWasteManagementCollapse
} from '../../app/shared/Sidebar'
import { useAppContext } from '../../AppContext'
import { Collapse } from '@mui/material'
import { Sms, SupportAgent } from '@mui/icons-material'

const StyledBoxForShadow = styled(Box)(({ theme }) => ({
  top: 60,
  left: -8,
  zIndex: -1,
  opacity: 0,
  position: 'absolute',
  pointerEvents: 'none',
  width: 'calc(100% + 15px)',
  height: theme.mixins.toolbar.minHeight,
  transition: 'opacity .15s ease-in-out',
  '&.scrolled': {
    opacity: 1
  }
}))

function Navigation(props) {
  // ** Props
  const { hidden, settings, beforeNavMenuContent, navMenuContent: userNavMenuContent } = props

  // ** States
  const { navCollapsed } = settings
  const { donor } = useAppContext()
  const [navHover, setNavHover] = useState(false)
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])

  const location = useLocation()

  function isPathActive(path) {
    return location.pathname.startsWith(path)
  }

  function isPathActiveExact(path) {
    return location.pathname === path
  }

  // ** Ref
  const shadowRef = useRef(null)

  const navMenuContentProps = {
    ...props,
    navHover,
    groupActive,
    setGroupActive,
    currentActiveGroup,
    setCurrentActiveGroup
  }

  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = ref => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect
      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect()

        return { ...original, height: Math.floor(original.height) }
      }
    }
  }

  // ** Scroll Menu
  const scrollMenu = container => {
    if (!beforeNavMenuContent) {
      container = hidden ? container.target : container
      if (shadowRef && container.scrollTop > 0) {
        // @ts-ignore
        if (!shadowRef?.current?.classList?.contains('scrolled')) {
          // @ts-ignore
          shadowRef?.current?.classList?.add('scrolled')
        }
      } else {
        // @ts-ignore
        shadowRef?.current?.classList?.remove('scrolled')
      }
    }
  }

  useEffect(() => {
    if (navCollapsed) {
      setNavHover(false)
    }
  }, [navCollapsed])

  const ScrollWrapper = PerfectScrollbar

  return (
    <Drawer {...props} navHover={navHover} setNavHover={setNavHover}>
      <VerticalNavHeader {...props} navHover={navHover} />

      <StyledBoxForShadow ref={shadowRef} />

      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          height: navCollapsed && !navHover ? '100vh' : 'calc(100vh - 160px)'
        }}
      >
        {/* @ts-ignore */}
        <ScrollWrapper
          {...(hidden
            ? {
              onScroll: container => scrollMenu(container),
              sx: { height: '100%', overflowY: 'auto', overflowX: 'hidden' }
            }
            : {
              options: { wheelPropagation: false },
              onScrollY: container => scrollMenu(container),
              containerRef: ref => handleInfiniteScroll(ref)
            })}
        >
          {beforeNavMenuContent ? beforeNavMenuContent(navMenuContentProps) : null}
          {userNavMenuContent ? (
            userNavMenuContent(navMenuContentProps)
          ) : (
            <List
              className='nav '
              // className="nav-items items-center"
              sx={{ pt: 0, '& > :first-of-type': { mt: '0' } }}
            >
              {getEntityInfo() == '5' ? (
                <>
                  {checkPermission('LIST_DONORS') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/donors'
                      active={isPathActive('/donors')}
                      title='All Donors'
                      icon='/assets/images/sidebar/donor.png'
                    />
                  ) : null}

                  <Collapse in={donor != ''}>
                    <SideMenuDonorCollapse navHover={navHover} />
                  </Collapse>
                  {checkPermission('VIEW_REQUEST') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/blood-requisition'
                      active={isPathActive('/blood-requisition')}
                      title='Blood Request/Issue'
                      icon='/assets/images/blood-requestion.svg'
                    />
                  ) : null}
                  {checkPermission('READ_BILLING') || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/billing'
                      active={isPathActive('/billing')}
                      title='Billing'
                      icon='/assets/images/sidebar/Billing.svg'
                    />
                  ) : null}
                  {checkPermission('VIEW_TTI_TESTING') || getTenatNameSans() != '' ? (
                    <SideMenuCustomCollapse
                      navHover={navHover}
                      clickFn={() => { }}
                      active={isPathActive('/serology')}
                      title='Lab'
                      icon='/assets/images/modal/flask1.png'
                    >
                      <li>
                        <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                          {checkPermission('VIEW_TTI_TESTING') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/serology/lab-testing'
                                onClick={() => localStorage.removeItem('donor')}
                                className={isPathActive('/serology/lab-testing') ? ' active' : ''}
                              >
                                TTI Testing
                              </Link>
                            </li>
                          ) : null}
                          {checkPermission('VIEW_TTI_TESTING') || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/serology/blood-group-testing'
                                onClick={() => localStorage.removeItem('donor')}
                                className={isPathActive('/serology/blood-group-testing') ? ' active' : ''}
                              >
                                Blood Grouping
                              </Link>
                            </li>
                          ) : null}
                        </ul>{' '}
                      </li>
                    </SideMenuCustomCollapse>
                  ) : null}

                  {/* {checkPermission('CREATE_BLOODS') || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/bulk-inventory'
                      active={isPathActive('/bulk-inventory')}
                      title='Bulk Inventory'
                      icon='/assets/images/alldonors/invwhite.svg'
                    />
                  ) : null} */}
                  {checkPermission('CREATE_BLOODS') || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/blood-processing'
                      active={isPathActive('/blood-processing')}
                      title='Blood Processing'
                      icon='/assets/images/alldonors/invwhite.svg'
                    />
                  ) : null}
                  {checkPermission('READ_BBR') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/blood-loan/recieve'
                      active={isPathActive('/blood-loan/recieve')}
                      title='Inward Stock'
                      icon='/assets/images/blood-loan.svg'
                    />
                  ) : null}
                  {checkPermission('LIST_BLOODS') || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/blood-stock'
                      active={isPathActive('/blood-stock')}
                      title='Current Blood Stock'
                      icon='/assets/images/sidebar/bloodStock.svg'
                    />
                  ) : null}
                  {checkPermission('READ_MASTER_REGISTER') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/master-register'
                      active={isPathActive('/master-register')}
                      title='Master Register'
                      icon='/assets/images/sidebar/registration.svg'
                    />
                  ) : null}

                  {checkPermission('READ_REPORT') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/reports'
                      active={isPathActive('/reports')}
                      title='Report & Registers'
                      icon='/assets/images/sidebar/bloodStock.svg'
                    />
                  ) : null}
                  {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                    <SideMenuCampCustomCollapse
                      clickFn={() => {
                        localStorage.removeItem('donor')
                      }}
                      navHover={navHover}
                      active={isPathActive('/blood-camp-managemen')}
                      title='Camp Management'
                      icon='/assets/images/Bloodcamp.svg'
                    >
                      <li>
                        <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                          {checkPermission('ADD_CAMP') === true && (
                            <li>
                              <Link
                                to='/blood-camp-management/add'
                                // onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/blood-camp-management/add') ? ' active' : ''}
                              >
                                Add Camp
                              </Link>
                            </li>
                          )}
                          {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/blood-camp-management/facility'
                                // onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/blood-camp-management/facility') ? ' active' : ''}
                              >
                                Facility Inspection
                              </Link>
                            </li>
                          ) : null}
                          {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/blood-camp-management/staff'
                                //  onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/blood-camp-management/staff') ? ' active' : ''}
                              >
                                Staff
                              </Link>
                            </li>
                          ) : null}
                          {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/blood-camp-management/items'
                                //  onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/blood-camp-management/items') ? ' active' : ''}
                              >
                                Add Items
                              </Link>
                            </li>
                          ) : null}
                          {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/blood-camp-management/post-camp-report'
                                //  onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/blood-camp-management/post-camp-report') ? ' active' : ''}
                              >
                                Post Camp Report
                              </Link>
                            </li>
                          ) : null}
                        </ul>{' '}
                      </li>
                    </SideMenuCampCustomCollapse>
                  ) : null}
                  {/* {checkPermission('READ_CERTIFICATE') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/certification'
                      active={isPathActive('/certification')}
                      title='Certification'
                      icon='/assets/images/sidebar/certificate.svg'
                    />
                  ) : null} */}
                  {checkPermission('VIEW_WASTE') === true || getTenatNameSans() != '' ? (
                    <SideMenuWasteManagementCollapse
                      clickFn={() => {
                        localStorage.removeItem('donor')
                      }}
                      navHover={navHover}
                      active={isPathActive('/waste-management')}
                      title='Waste Management'
                      icon='/assets/images/alldonors/bin.png'
                    >
                      <li>
                        <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                          {checkPermission('ADD_CAMP') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/waste-management/waste-list'
                                // onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/waste-management/waste-list') ? ' active' : ''}
                              >
                                Waste List
                              </Link>
                            </li>
                          ) : null}
                          {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                            <li>
                              <Link
                                to='/waste-management/autoclave'
                                // onClick={() => localStorage.removeItem("donor")}
                                className={isPathActive('/waste-management/autoclave') ? ' active' : ''}
                              >
                                Autoclave
                              </Link>
                            </li>
                          ) : null}
                        </ul>{' '}
                      </li>
                    </SideMenuWasteManagementCollapse>
                  ) : null}
                  {checkPermission('GENERATE_QR_CODE') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/barcodes'
                      active={isPathActiveExact('/barcodes')}
                      title='QR Code Generation'
                      icon='/assets/images/sidebar/qr-code.svg'
                    />
                  ) : null}
                  {checkPermission('GENERATE_BAR_CODE') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/barcodes-multiple'
                      active={isPathActive('/barcodes-multiple')}
                      title='Bar Code Generation'
                      icon='/assets/images/sidebar/barcode_1.svg'
                    />
                  ) : null}
                  {checkPermission('READ_ITEM_STOCK') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/stock-items'
                      active={isPathActive('/stock-items')}
                      title='Store Item'
                      icon='/assets/images/sidebar/bloodStock.svg'
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {checkPermission('LIST_TENANTS') && (
                    <li className={isPathActive('/tenants') ? 'nav-item active' : 'nav-item'}>
                      <Link
                        onClick={() => {
                          localStorage.removeItem('tenant')
                          localStorage.removeItem('donor')
                        }}
                        className='nav-link d-flex justify-content-start align-items-start w-100 mx-0'
                        to='/tenants'
                      >
                        <div className='sidebar-list-items'>
                          <img src='/assets/images/sidebar/tenantManagement.svg' alt='Tenant Managment' />
                          <span className='menu-title'>Tenant Managment </span>
                        </div>
                      </Link>
                    </li>
                  )}
                  {checkPermission('LIST_TENANTS') && (
                    <li className={isPathActive('/advance-features') ? 'nav-item active' : 'nav-item'}>
                      <Link
                        onClick={() => {
                          localStorage.removeItem('tenant')
                          localStorage.removeItem('donor')
                        }}
                        className='nav-link d-flex justify-content-start align-items-start w-100 mx-0'
                        to='/advance-features'
                      >
                        <div className='sidebar-list-items'>
                          <img src='/assets/images/sidebar/tenantManagement.svg' alt='Tenant Managment' />
                          <span className='menu-title'>Advance Features </span>
                        </div>
                      </Link>
                    </li>
                  )}

                  {getTenatNameSans() != '' && (
                    <motion.div
                      variants={{
                        collapsed: { scale: 0.8 },
                        open: { scale: 1 }
                      }}
                      transition={{ duration: 0.8 }}
                      className='content-placeholder '
                    >
                      <div
                        onClick={() => {
                          localStorage.removeItem('donor')
                          document.getElementById('drop-down-donors').classList.remove('drop-down-clicked')
                          document.getElementById('donor__profileDiv').remove()
                        }}
                        className='tenant-user nav-item nav-profile'
                      >
                        <span className='nav-link' onClick={evt => evt.preventDefault()}>
                          <div className='nav-profile-image'>
                            <p>{getInitials(getTenatNameSans())}</p>
                            <span className='login-status online' />{' '}
                          </div>
                          <div className='nav-profile-text'>
                            <span className='font-weight-bold mb-2'>{getTenatNameSans()} dd</span>
                          </div>
                        </span>
                      </div>
                    </motion.div>
                  )}

                  {getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => {
                        localStorage.removeItem('donor')
                      }}
                      path='/tenant/dashboard'
                      active={isPathActive('/tenant/dashboard')}
                      title='Dashboard'
                      icon='/assets/images/sidebar/Dashboard.svg'
                    />
                  ) : null}

                  {checkPermission('LIST_DONORS') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/donors'
                      active={isPathActive('/donors')}
                      title='All Donors'
                      icon='/assets/images/sidebar/donor.png'
                    />
                  ) : null}

                  <Collapse in={donor != ''}>
                    <SideMenuDonorCollapse navHover={navHover} />
                  </Collapse>

                  {checkPermission('READ_MASTER_REGISTER') === true || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/master-register'
                      active={isPathActive('/master-register')}
                      title='Master Register'
                      icon='/assets/images/sidebar/registration.svg'
                    />
                  ) : null}

                  {getEntityInfo() != 1 || getTenatNameSans() != '' ? (
                    <>
                      {checkPermission('VIEW_TTI_TESTING') || getTenatNameSans() != '' ? (
                        <SideMenuCustomCollapse
                          navHover={navHover}
                          clickFn={() => { }}
                          active={isPathActive('/serology')}
                          title='Lab'
                          icon='/assets/images/modal/flask1.png'
                        >
                          <li>
                            <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                              {checkPermission('VIEW_TTI_TESTING') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/serology/lab-testing'
                                    onClick={() => localStorage.removeItem('donor')}
                                    className={isPathActive('/serology/lab-testing') ? ' active' : ''}
                                  >
                                    TTI Testing
                                  </Link>
                                </li>
                              ) : null}
                              {checkPermission('VIEW_TTI_TESTING') || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/serology/blood-group-testing'
                                    onClick={() => localStorage.removeItem('donor')}
                                    className={isPathActive('/serology/blood-group-testing') ? ' active' : ''}
                                  >
                                    Blood Grouping
                                  </Link>
                                </li>
                              ) : null}
                            </ul>{' '}
                          </li>
                        </SideMenuCustomCollapse>
                      ) : null}

                      {/* {checkPermission('CREATE_BLOODS') || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/bulk-inventory'
                          active={isPathActive('/bulk-inventory')}
                          title='Bulk Inventory'
                          icon='/assets/images/alldonors/invwhite.svg'
                        />
                      ) : null} */}
                      {checkPermission('CREATE_BLOODS') || getTenatNameSans() != '' ? (
                    <SideMenuCustom
                      navCollapsed={navCollapsed}
                      navHover={navHover}
                      clickFn={() => localStorage.removeItem('donor')}
                      path='/blood-processing'
                      active={isPathActive('/blood-processing')}
                      title='Blood Processing'
                      icon='/assets/images/alldonors/invwhite.svg'
                    />
                  ) : null}
                      {checkPermission('READ_BILLING') || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/billing'
                          active={isPathActive('/billing')}
                          title='Billing'
                          icon='/assets/images/sidebar/Billing.svg'
                        />
                      ) : null}

                      {checkPermission('LIST_BLOODS') || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/blood-stock'
                          active={isPathActive('/blood-stock')}
                          title='Current Blood Stock'
                          icon='/assets/images/sidebar/bloodStock.svg'
                        />
                      ) : null}
                      {checkPermission('VIEW_WASTE') === true || getTenatNameSans() != '' ? (
                        <SideMenuWasteManagementCollapse
                          clickFn={() => {
                            localStorage.removeItem('donor')
                          }}
                          navHover={navHover}
                          active={isPathActive('/waste-management')}
                          title='Waste Management'
                          icon='/assets/images/alldonors/bin.png'
                        >
                          <li>
                            <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                              {checkPermission('ADD_CAMP') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/waste-management/waste-list'
                                    // onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/waste-management/waste-list') ? ' active' : ''}
                                  >
                                    Waste List
                                  </Link>
                                </li>
                              ) : null}
                              {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/waste-management/autoclave'
                                    // onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/waste-management/autoclave') ? ' active' : ''}
                                  >
                                    Autoclave
                                  </Link>
                                </li>
                              ) : null}
                            </ul>{' '}
                          </li>
                        </SideMenuWasteManagementCollapse>
                      ) : null}

                      {checkPermission('READ_BBT') === true ||
                        checkPermission('READ_BBR') === true ||
                        getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/blood-loan'
                          active={isPathActive('/blood-loan')}
                          title='Bulk Transfer/Receiver'
                          icon='/assets/images/blood-loan.svg'
                        />
                      ) : null}

                      {checkPermission('VIEW_REQUEST') === true || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/blood-requisition'
                          active={isPathActive('/blood-requisition')}
                          title='Blood Request/Issue'
                          icon='/assets/images/blood-requestion.svg'
                        />
                      ) : null}
                      {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                        <SideMenuCampCustomCollapse
                          clickFn={() => {
                            localStorage.removeItem('donor')
                          }}
                          navHover={navHover}
                          active={isPathActive('/blood-camp-managemen')}
                          title='Camp Management'
                          icon='/assets/images/Bloodcamp.svg'
                        >
                          <li>
                            <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                              {checkPermission('ADD_CAMP') === true && (
                                <li>
                                  <Link
                                    to='/blood-camp-management/add'
                                    // onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/blood-camp-management/add') ? ' active' : ''}
                                  >
                                    Add Camp
                                  </Link>
                                </li>
                              )}
                              {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/blood-camp-management/facility'
                                    // onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/blood-camp-management/facility') ? ' active' : ''}
                                  >
                                    Facility Inspection
                                  </Link>
                                </li>
                              ) : null}
                              {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/blood-camp-management/staff'
                                    //  onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/blood-camp-management/staff') ? ' active' : ''}
                                  >
                                    Staff
                                  </Link>
                                </li>
                              ) : null}
                              {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/blood-camp-management/items'
                                    //  onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/blood-camp-management/items') ? ' active' : ''}
                                  >
                                    Add Items
                                  </Link>
                                </li>
                              ) : null}
                              {checkPermission('VIEW_CAMP') === true || getTenatNameSans() != '' ? (
                                <li>
                                  <Link
                                    to='/blood-camp-management/post-camp-report'
                                    //  onClick={() => localStorage.removeItem("donor")}
                                    className={isPathActive('/blood-camp-management/post-camp-report') ? ' active' : ''}
                                  >
                                    Post Camp Report
                                  </Link>
                                </li>
                              ) : null}
                            </ul>{' '}
                          </li>
                        </SideMenuCampCustomCollapse>
                      ) : null}
                      {/* {checkPermission('READ_CERTIFICATE') === true || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/certification'
                          active={isPathActive('/certification')}
                          title='Certification'
                          icon='/assets/images/sidebar/certificate.svg'
                        />
                      ) : null} */}

                      {checkPermission('READ_REPORT') === true || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/reports'
                          active={isPathActive('/reports')}
                          title='Report & Registers'
                          icon='/assets/images/sidebar/bloodStock.svg'
                        />
                      ) : null}

                      {checkPermission('GENERATE_QR_CODE') === true || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/barcodes'
                          active={isPathActiveExact('/barcodes')}
                          title='QR Code Generation'
                          icon='/assets/images/sidebar/qr-code.svg'
                        />
                      ) : null}
                      {checkPermission('GENERATE_BAR_CODE') === true || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/barcodes-multiple'
                          active={isPathActive('/barcodes-multiple')}
                          title='Bar Code Generation'
                          icon='/assets/images/sidebar/barcode_1.svg'
                        />
                      ) : null}
                      {checkPermission('READ_ITEM_STOCK') === true || getTenatNameSans() != '' ? (
                        <SideMenuCustom
                          navCollapsed={navCollapsed}
                          navHover={navHover}
                          clickFn={() => localStorage.removeItem('donor')}
                          path='/stock-items'
                          active={isPathActive('/stock-items')}
                          title='Store Items'
                          icon='/assets/images/sidebar/bloodStock.svg'
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              )}

              <SideMenuCustom
                navCollapsed={navCollapsed}
                navHover={navHover}
                IconMui={<SupportAgent />}
                clickFn={() => localStorage.removeItem('donor')}
                path='/support'
                active={isPathActive('/support')}
                title='Support'
                icon='/assets/images/sidebar/bloodStock.svg'
              />
            </List>
          )}
        </ScrollWrapper>
      </Box>
      {navCollapsed && !navHover ? null : (
        <section className='footer-section  '>
          <p className='footer-note'>© EHR Logic. All rights reserved</p>
        </section>
      )}
      {/* {afterNavMenuContent ? afterNavMenuContent(navMenuContentProps) : null} */}
    </Drawer>
  )
}

export default Navigation
