import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormHelperText, InputAdornment } from '@mui/material';
import { usersListByRole } from '../../helper/CommonAPI';

const RHFUserRolesAutocomplete = (props) => {
    const { control } = useFormContext();
    const { name, size, handleChange, role, value, onChange, startAdornment, ...other } = props;
    const options = usersListByRole(role);

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: true,
            }}
            render={({ field, fieldState: { error } }) => {
                const { onChange: fieldOnChange, value: fieldValue, ref } = field;

                return (
                    <>
                        <Autocomplete
                            size={size || 'small'}
                            value={
                                options.find((option) => option.id === (fieldValue || value)) || 
                                (fieldValue || value ? { label: fieldValue || value } : null)
                            }
                            getOptionLabel={(option) => option.label || ''}
                            freeSolo
                            onChange={(event, newValue) => {
                                const newValueId = newValue?.id || newValue?.label || newValue || '';
                                fieldOnChange(newValueId);
                                if (onChange) {
                                    onChange(event, newValueId);
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                if (!newInputValue) {
                                    fieldOnChange('');
                                    if (onChange) {
                                        onChange(event, '');
                                    }
                                }
                            }}
                            id="controllable-states-demo"
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputRef={ref}
                                    error={Boolean(error)}
                                    onChange={(e) => {
                                        fieldOnChange(e.target.value);
                                        if (onChange) {
                                            onChange(e, e.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        
                                        startAdornment: startAdornment ? (
                                            <InputAdornment  position="start">
                                                {startAdornment}
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                />
                            )}
                            {...other}
                        />
                        {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
                    </>
                );
            }}
        />
    );
};

export default RHFUserRolesAutocomplete;
